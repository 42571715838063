<template>
  <div class="chart-container">
    <b-card class="job-card-status">
      <b-card-header>
        <b-card-title>Job Card Status Distribution</b-card-title>
      </b-card-header>
      <apexcharts ref="chart" height="350" type="pie" :options="chartOptions" :series="chartData"></apexcharts>
    </b-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { BCard, BCardTitle, BCardHeader } from "bootstrap-vue";

export default {
  name: "JobCardStatusChart",
  props: {
    jobCards: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    apexcharts: VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
  },
  computed: {
    chartOptions() {
      return {
        labels: ["Not Started", "Started", "Completed", "Cancelled", "Blocked"],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      };
    },
    chartData() {
      const statusCounts = [0, 0, 0, 0, 0]; // Corresponding to the enum values

      this.jobCards.forEach(jobCard => {
        const status = jobCard.status;
        if (status >= 0 && status <= 4) {
          statusCounts[status]++;
        }
      });

      return statusCounts;
    },
  },
};
</script>

<style scoped>
/* Add the custom styles from the previous example */
</style>
