<template>
  <b-container>
    <div class="d-flex justify-content-center mb-1">
      <b-spinner variant="success" label="Spinning" block v-if="loading" style="width: 3rem; height: 3rem"></b-spinner>
    </div>
    <b-row>
      <b-col md="12">
        <ProductionChart :jobCards="jobcardList" v-if="!loading" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col>
        <ChartjsHorizontalBarChart :jobCards="jobcardList" v-if="!loading" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ChartjsBarChart from "./ChartjsBarChart.vue";
import ProductionChart from "./ProductionChart.vue";
import ChartjsHorizontalBarChart from "./ChartjsHorizontalBarChart";
import { BContainer, BRow, BCol, BSpinner } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BContainer,
    BRow,
    ChartjsHorizontalBarChart,
    ProductionChart,
    BCol,
    ChartjsBarChart,
    BSpinner,
  },
  computed: {
    ...mapGetters("jobcardModule", {
      jobcardList: "jobcards",
      loading: "loading",
    }),
  },
  methods: {
    ...mapActions("jobcardModule", ["getJobcardListAction"]),
  },
  async mounted() {
    await this.getJobcardListAction()
      .then(() => {
        console.log("Jobcard List", this.jobcardList);
      })
      .catch((response) => {
        console.log(response);
      });
  },
};
</script>
