<template>
  <div class="chart-container">
    <b-card class="lead-time-card">
      <b-card-header>
        <b-card-title>Lead Time Analysis for Completed Job Cards</b-card-title>
      </b-card-header>
      <apexcharts ref="chart" height="350" type="bar" :options="chartOptions" :series="chartData"></apexcharts>
    </b-card>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { BCard, BCardTitle, BCardHeader } from "bootstrap-vue";

export default {
  name: "LeadTimeChart",
  props: {
    jobCards: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    apexcharts: VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
  },
  computed: {
    chartOptions() {
      return {
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          title: {
            text: "Lead Time (hours)",
          },
        },
        xaxis: {
          categories: this.completedJobCardNames,
          title: {
            text: "Job Cards",
          },
        },
      };
    },
    completedJobCardNames() {
      return this.jobCards
        .filter((jobCard) => jobCard.status === 2)
        .map((jobCard) => jobCard.name);
    },
    chartData() {
      const completedJobCards = this.jobCards.filter((jobCard) => jobCard.status === 2);
      const leadTimes = completedJobCards.map((jobCard) => {
        const leadTimeHours = (new Date(jobCard.lastModifiedOn) - new Date(jobCard.createdOn)) / (1000 * 60 * 60); // in hours
        return parseFloat(leadTimeHours.toFixed(2)); // round to 2 decimal places and convert back to a float
      });

      return [
        {
          name: "Lead Time",
          data: leadTimes,
        },
      ];
    },
  },
};
</script>

<style scoped>
.lead-time-card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>